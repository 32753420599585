<template>
  <div class="marge">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <div class="marge-content page-layout">
      <md-card class="meep-form">
        <PageHeader
            :title="$t('simulateurs.marge.title')"
            :has-back="true"
            @back="goBack()"
        />
        <md-card-content>
          <form class="meep-form-column" @submit.prevent="calcu(form)">
            <Chooser
                v-model="form.type"
                :value="form.type"
                :items="[
                { label: 'Prix TTC', value: 'prix' },
                { label: 'Coefficient', value: 'coefficient' },
              ]"
            />

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.marge.prixAchat") }}</label>
                <md-input
                    v-model.number="$v.form.prixAchat.$model"
                    name="prixAchat"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.prixAchat.$dirty">
                  <span v-show="!$v.form.prixAchat.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.prixAchat.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div v-if="form.type === 'prix'" class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.marge.prixVente") }}</label>
                <md-input
                    v-model.number="$v.form.prixVente.$model"
                    name="prixVente"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.prixVente.$dirty">
                  <span v-show="!$v.form.prixVente.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.prixVente.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div v-else class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.marge.coefficient") }}</label>
                <md-input
                    v-model.number="$v.form.coefficient.$model"
                    name="coefficient"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.coefficient.$dirty">
                  <span
                      v-show="!$v.form.coefficient.numeric"
                      class="meep-error"
                  >
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span
                      v-show="!$v.form.coefficient.required"
                      class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label for="scope">
                  {{ $t("simulateurs.marge.taux") }}
                </label>

                <md-select id="type" v-model="$v.form.tva.$model" name="type">
                  <md-option
                      v-for="(tva, index) in tauxs"
                      :key="index"
                      :value="tva.value"
                  >
                    {{ tva.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" type="submit">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>
          </form>
          <div class="meep-form-column">
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.marge.prixAchatHT") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.prixAchatHT) +
                    $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.marge.prixVenteHT") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.prixVenteHT) +
                    $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.marge.prixVenteTTC") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.prixVenteTTC) +
                    $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.marge.margeVente") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.margeVente) +
                    $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.marge.coefficient-black") }}</label>
                <p class="result">
                  {{ results.coefficient }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.marge.tauxMarge") }}</label>
                <p class="result">
                  {{ results.tauxMarge + $t("suffix.percent") }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import { numeric, required, requiredIf } from "vuelidate/lib/validators";
import PageHeader from "@/components/PageHeader";
import Chooser from "@/components/Chooser.vue";
import { tauxs } from "../../constants";

export default {
  name: "Marge",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
    Chooser,
  },

  validations: {
    form: {
      prixAchat: { required, numeric },
      prixVente: {
        numeric,
        required: requiredIf(function() {
          return this.form.type === "prix";
        }),
      },
      coefficient: {
        numeric,
        required: requiredIf(function() {
          return this.form.type !== "prix";
        }),
      },
      type: {
        required,
      },
      tva: {},
    },
  },

  data() {
    return {
      form: {
        prixAchat: null,
        prixVente: null,
        coefficient: null,
        tva: null,
        type: "prix",
      },
      tauxs,
      results: {
        prixAchatHT: 0,
        prixVenteHT: 0,
        prixVenteTTC: 0,
        margeVente: 0,
        coefficient: 0,
        tauxMarge: 0,
      },
    };
  },

  methods: {
    calcu({ prixAchat, prixVente, tva, coefficient }) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (!tva) {
          tva = 120;
        }

        if (this.form.type === "prix") {
          coefficient = 0;
        } else {
          prixVente = 0;
        }

        let coef, prixVenteTTC;

        if (coefficient) {
          coef = coefficient;
          prixVenteTTC = coefficient * prixAchat;
        } else {
          prixVenteTTC = prixVente;
          coef = prixVenteTTC / prixAchat;
        }

        const prixVenteHT = prixVenteTTC * (100 / tva);
        const margeVente = prixVenteHT - prixAchat;
        const tauxMarge = (margeVente / prixAchat) * 100;

        this.results = {
          prixAchatHT: Number(prixAchat),
          prixVenteHT: prixVenteHT,
          prixVenteTTC: Number(prixVenteTTC),
          margeVente: margeVente,
          coefficient: Number(coef),
          tauxMarge: tauxMarge,
        };
      }
    },
  },
};
</script>
